<template>
  <div class="notifications container">
    <div class="header">
      <div class="title-box">
        <p class="title">历史公告</p>
      </div>
    </div>
    <ul class="content">
        <li v-for="content in contents" :key="content.id" @click="articleAction(content.id)">
          <p class="text">{{ content.content_limit }}</p>
          <p class="date">{{ content.created_at | formatTimeInterval('YYYY-MM-DD') }}</p>
        </li>
      </ul>
      <el-pagination
      class="pagination"
      background
      hide-on-single-page
      :current-page="page"
      :page-size="pageSize"
      @current-change="pageChanged"
      layout="prev, pager, next"
      :total="total"></el-pagination>
  </div>
</template>

<script>
import { Pagination } from 'element-ui'

export default {
  name: 'Notifications',
  components: {
    [Pagination.name]: Pagination
  },
  data () {
    return {
      page: 1,
      pageSize: 13,
      total: 0,
      contents: []
    }
  },
  mounted () {
    this.requestGetData()
  },
  methods: {
    articleAction (id) {
      this.$router.push('/lottery/article/' + id)
    },
    pageChanged (page) {
      this.page = page
      this.requestGetData()
    },
    requestGetData () {
      this.$loading.show()
      this.$api.article(2, this.page, this.pageSize).then(res => {
        this.contents = res.data.list
        this.total = res.data.total
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        this.$message.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
.notifications {
  padding-top: 30px;
  padding-bottom: 40px;
  > .header {
    padding: 0 18px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    > .title-box {
      display: flex;
      align-items: center;
      height: 45px;

      > .title {
        font-size: 18px;
        font-weight: 500;
        color: #222;
      }
    }
  }

  > .content {
    margin: 0;
    padding: 36px 30px;
    list-style: none;
    background-color: #fff;
    > li {
      cursor: pointer;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: rgba($color: #FFCCAA, $alpha: 0.4);
      }

      .text {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }

      .date {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  > .pagination {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
